import React, { Component } from "react";
import { Form, Input, Label, Segment, Header, TextArea, List, Button, Modal, Divider, Message, Icon, Grid } from 'semantic-ui-react'
import TextBox from "./TextBox"
import config from "../config";
import Select from "semantic-ui-react/dist/commonjs/addons/Select/Select";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { invokeApig } from "../libs/awsLib";

export default class AddressSearch extends Component {
constructor(props)
{
  super(props);
  this.state = {addresses: [], searchpostcode: "", searchComplete: false};
  this.findAddresses=this.findAddresses.bind(this);
  this.selectAddress=this.selectAddress.bind(this);

}



findAddresses()
{
  // event.preventDefault();
  var url = new URL("https://"+config.getAddress.URL+"/find/"+this.state.searchpostcode);
  url.searchParams.append("api-key", config.getAddress.APIKey);
  url.searchParams.append("expand", true);
  fetch(url).then(response=>response.json()).then(addresses=>{
    if ('addresses' in addresses)
    {
      addresses.addresses.forEach(address=>{address.postcode=addresses.postcode});
      this.setState({addresses: addresses.addresses, searchComplete: true});
    }
    else
    this.setState({addresses: [], searchComplete: true})
    // console.log(addresses);
  }).catch(err=>{console.log(err)})
  // var idealPostcodes=require("ideal-postcodes")(config.idealPostcodes.apiKey)
  // idealPostcodes.lookupAddress(this.state.postcode, (error, addresses)=>{this.setState({addresses: addresses.result.hits})})
}

async componentDidMount() {
  const nulltoemptystr= (str)=>{return str===null ? "" : str};
  
  this.setState({subbuildingname: nulltoemptystr(this.props.subbuildingname), buildingname: nulltoemptystr(this.props.buildingname),  buildingnumber: nulltoemptystr(this.props.buildingnumber), dependentthoroughfare: nulltoemptystr(this.props.dependentthoroughfare), thoroughfare: nulltoemptystr(this.props.thoroughfare), doubledependentlocality: nulltoemptystr(this.props.doubledependentlocality), dependentlocality: nulltoemptystr(this.props.dependentlocality), posttown: nulltoemptystr(this.props.posttown), county: nulltoemptystr(this.props.county),  postcode: nulltoemptystr(this.props.postcode), subbuildingname: nulltoemptystr(this.props.subbuildingname), buildingname: nulltoemptystr(this.props.buildingname), buildingnumber: nulltoemptystr(this.props.buildingnumber), dependentthoroughfare: nulltoemptystr(this.props.dependentthoroughfare), thoroughfare: nulltoemptystr(this.props.thoroughfare), doubledependentlocality: nulltoemptystr(this.props.doubledependentlocality), dependentlocality: nulltoemptystr(this.props.dependentlocality), posttown: nulltoemptystr(this.props.posttown), county: nulltoemptystr(this.props.county),  postcode: nulltoemptystr(this.props.postcode)})
}

componentWillReceiveProps(newProps)
{
  // const nulltoemptystr= (str)=>{return str===null ? "" : str};
  // if (newProps.subbuildingname!==this.props.subbuildingname || newProps.buildingname!==this.props.buildingname || newProps.buildingnumber!==this.props.buildingnumber || newProps.thoroughfare!==this.props.thoroughfare || newProps.dependentthoroughfare!==this.props.dependentthoroughfare || newProps.doubledependentlocality!==this.props.doubledependentlocality || newProps.dependentlocality!==this.props.dependentlocality || newProps.posttown!==this.props.posttown || newProps.county!==this.props.county  || newProps.postcode!==this.props.postcode)
  // {
  //   this.setState({subbuildingname: nulltoemptystr(newProps.subbuildingname), buildingname: nulltoemptystr(newProps.buildingname),  buildingnumber: nulltoemptystr(newProps.buildingnumber), dependentthoroughfare: nulltoemptystr(newProps.dependentthoroughfare), thoroughfare: nulltoemptystr(newProps.thoroughfare), doubledependentlocality: nulltoemptystr(newProps.doubledependentlocality), dependentlocality: nulltoemptystr(newProps.dependentlocality), posttown: nulltoemptystr(newProps.posttown), county: nulltoemptystr(newProps.county),  postcode: nulltoemptystr(newProps.postcode), addresses: [], searchpostcode: ""});
  // }
}

selectAddress(event, data)
{
  const address=this.state.addresses[data.value];
  const formattedaddress=address.formatted_address.filter(line=>{return line.length>0});
  this.props.onChange(address.sub_building_name, address.building_name, address.building_number, address.dependant_thoroughfare, address.thoroughfare, address.double_dependent_locality, address.dependant_locality, address.town_or_city, address.county, address.postcode, formattedaddress)
}


  render() {

    const addressOptions=this.state.addresses===[] ? [] : this.state.addresses.map((address, index)=>{return ({key: index, value: index, text: address.formatted_address[0] + (address.formatted_address[1].length>0 ? ', ' + address.formatted_address[1] : "")+ (address.formatted_address[2].length>0 ? ', ' + address.formatted_address[2] : "")+ (address.formatted_address[3].length>0 ? ', ' + address.formatted_address[3] : "")+ (address.formatted_address[4].length>0 ? ', ' + address.formatted_address[4] : "")})});
    return (
        <Form>
        <Form.Field><label>{this.props.label}</label></Form.Field>

        <Form.Input label='Post Code' placeholder='Post Code' value={this.state.searchpostcode} onChange={(event, data)=>{this.setState({searchpostcode: data.value})}}/>
        <Button className='seaglasssecondarybutton' onClick={this.findAddresses}>Find Addresses</Button>
        <Form.Field>
        <label>Select address:</label>
        <Select options={addressOptions} onChange={this.selectAddress} />
        {this.state.searchComplete && <Label basic>{this.state.addresses.length===1 ? "1 address found" : this.state.addresses.length===0 ? 'No addresses found' : this.state.addresses.length + ' addresses found'}</Label>}
        </Form.Field>
        </Form>
    );
  }
}