  import React, { Component } from "react";
  import { Table, Container, Button, Dropdown, Segment, Icon, Form, Header, Ref } from 'semantic-ui-react'
  import { invokeApig } from "../libs/awsLib";
  import { formatField, formatAddress, exporttocsv, formatDate, formatDateTime, exportToExcel } from "../libs/sharedFunctions";
  import LoadingPage from "./LoadingPage"
  import ErrorPage from "./ErrorPage"  
  import { Link } from "react-router-dom";
  import _ from 'lodash'  
  import './Report.css';
  import { isFulfilled } from "q";



  export default class Report extends Component {
  constructor(props)
  {
    super(props);
    this.state = {isLoading: false, results: [], sortcolumn: null, sortdirection: null, queryparameters: this.reportParamstoQueryParams(this.props.reportparameters), groupcounts: {}};

  }

  reportParamstoQueryParams(reportParams){
    const reducefn=(accumulator, currentvalue)=>{accumulator[currentvalue.name]=currentvalue.value; return accumulator}
    return reportParams.reduce(reducefn, {})
  }


  loadReportData(reportname, queryparameters) {
    
      //get view data    
      const queryParams=Object.keys(queryparameters).map(paramkey=>{return paramkey+'='+queryparameters[paramkey]})
      invokeApig({path: '/reports/' +reportname  , method: "GET",  body: null, queryParams: queryParams}).then((result)=>{
        
        if(result.status===200) 
        {
          //calculate group counts
          var groupcounts={};
          if (this.props.groupby.length>0)
            result.data.map(row=>{
              var groupvalue="";
              this.props.groupby.map(groupfield=>
                {
                  groupvalue +='_'+row[groupfield].toString();
                  if (groupvalue in groupcounts)
                    groupcounts[groupvalue]=groupcounts[groupvalue]+1
                  else
                    groupcounts[groupvalue]=1
                })
            })

          this.setState({results: result.data, groupcounts: groupcounts, isLoading: false, selectedrow: null, selectedrowkey: null, selectedid: null, loadError: false})}  
        else if (result.status===404)
        {
          this.setState({isLoading: false, loadError: true, loadErrorHeader: 'Page not found', loadErrorDetail: 'The page you requested was not found'})
        }
        else if (result.status===400)
        {
          this.setState({isLoading: false, loadError: true, loadErrorHeader: 'Error loading page', loadErrorDetail: result.data})
        }    
        else if (result.status===401)
        {
          this.setState({isLoading: false, loadError: true, loadErrorHeader: 'Permission denied', loadErrorDetail: 'You are not authorised to view this page'})
        }     
        else if (result.status===502)
        {
          this.setState({isLoading: false, loadError: true, loadErrorHeader: 'API Error', loadErrorDetail: 'The API returned an error'})
        }          
        else 
        {
          this.setState({isLoading: false, loadError: true, loadErrorHeader: 'Unexpected return code' + result.status, loadErrorDetail: result.data})
        }        
        
      }).catch(e=>
        {
          this.setState({isLoading: false, loadError: true, loadErrorHeader: 'An error occurred submitting the request', loadErrorDetail: []})
        })
      
    }

    executeLineFunction(linefunction, row)
    {
      //get function data
      var data={};
      linefunction.fields.forEach(fieldName=>{
        data[fieldName]=row[fieldName];
      })
      //peform function
      invokeApig({path: '/reports/functions/' +linefunction.function  , method: "POST",  body: data}).then((result)=>{
        
        if(result.status===200) 
        {
          alert(linefunction.successmessage)
        }
        else 
        {
            alert(linefunction.failmessage)
        }        
        
      }).catch(e=>
        {
          alert(linefunction.failmessage)
        })

    }

    componentDidMount()
    {
      // this.refreshData()
      // this.setState({queryparameters: queryparameters,results: [], groupcounts: {}, isLoading: false, selectedrow: null, selectedrowkey: null, selectedid: null, loadError: false})
    }
  
    refreshData()
    {
      this.setState({isLoading: true})
      this.loadReportData(this.props.reportname, this.state.queryparameters)
    }

    componentWillReceiveProps(nextProps)
    {
      if(this.props.reportname!==nextProps.reportname)
      {
        const queryparameters=this.reportParamstoQueryParams(nextProps.reportparameters)
        this.setState({queryparameters: queryparameters,results: [], groupcounts: {}, isLoading: false, selectedrow: null, selectedrowkey: null, selectedid: null, loadError: false})
        // this.loadReportData(nextProps.reportname, queryparameters)
  
      }
      else
      {
        //handle updates to report parameters
        var queryparameters=this.state.queryparameters;
        var paramsUpdated=false;
        nextProps.reportparameters.forEach((param, i)=>{
           if (param.value!==this.props.reportparameters[i].value)
           {
              queryparameters[param.name]=param.value;
              paramsUpdated=true;
           }

         })
         if (paramsUpdated)
         {
          this.setState({queryparameters: queryparameters})
          this.refreshData();
         }
         
      }
      
    }

    fieldValue(fieldname, row)
    {
      const value=row[fieldname];
      if (value)
        if (fieldname in this.props.columnformats)
        {
          const format=this.props.columnformats[fieldname];
          if (format==='date')
            return formatDate(new Date(value));
          else if (format==='datetime')
            return formatDateTime(new Date(value));
          else
            return value
        }
        else 
          return value

    }

    handleSort = clickedColumn => () => {
      const sortcolumn=this.state.sortcolumn;
      const sortdirection=this.state.sortdirection;
      const data=this.state.results;
      
      if (sortcolumn !== clickedColumn) {
        this.setState({
          sortcolumn: clickedColumn,
          results: _.sortBy(data, [clickedColumn]),
          sortdirection: 'ascending',
        })
      }
      else
    {
      this.setState({
        results: data.reverse(),
        sortdirection: sortdirection === 'ascending' ? 'descending' : 'ascending',
      })
    }
    }
  downloadCSV=()=>
  {
    const headers=this.props.fields.map(field=>{return field.label})
    const data=this.state.results.map(row=>{return this.props.fields.map(field=>{return this.fieldValue(field, row)})})
    const filename=this.props.parententity + '.'+ this.props.parentid + '.' + this.props.subentity + 's.csv'
    exporttocsv(headers, data, filename);
  }
  reportToExcel=()=>
  {
  exportToExcel(this.state.results, this.props.fieldnames, this.props.columnheadings, this.props.reporttitle)
  }
  

    render() {

      const updateQueryParameter=(name, value)=>
      {
        var queryparameters=Object.assign({}, this.state.queryparameters);
        queryparameters[name]=value;
        this.setState({queryparameters: queryparameters})
      }
    
    
      const renderReportParameter=(param)=>
      {
        if (param.fieldtype==='date')
        {
          return <Form.Input type='date' label={param.label} placeholder={param.label} value={this.state.queryparameters[param.name]} onChange={(event, data)=>{updateQueryParameter(param.name, data.value)}} />
        }
        else if (param.fieldtype==='varchar')
        {
          return <Form.Input label={param.label} placeholder={param.label} value={this.state.queryparameters[param.name]} onChange={(event, data)=>{updateQueryParameter(param.name, data.value)}} />
        }
      }

          const sortcolumn=this.state.sortcolumn
          const sortdirection=this.state.sortdirection
          var fieldsdisplayed ={};

          if (this.state.isLoading) 
          return (
          <Segment className="fullpage" >  
          <LoadingPage  loadingMessage={this.props.loadingMessage} indeterminate={this.props.indeterminate}/>
          </Segment>
          )
          else if (this.state.loadError)
          return (
            <Container>
            <ErrorPage visible={true} messageHeader={this.state.loadErrorHeader} messageDetail={this.state.loadErrorDetail}/>
            </Container>
          )
          else return (
          <div>
            <Header as='h2' className='seaglassblue'>{this.props.reporttitle}</Header>
            <Segment>
            <Form>
            <Form.Group inline>
            {this.props.reportparameters.map(param=>{return renderReportParameter(param)})}
            <Button className='seaglassbutton' onClick={()=>{this.refreshData()}}><Icon name={this.props.reportbuttonicon}/>{this.props.reportbuttonlabel}</Button>            
            <Button className='seaglassbutton' onClick={()=>{this.reportToExcel()}}><Icon name='file excel outline'/>Export</Button>
            </Form.Group>
            </Form>
            </Segment>
          <Segment className="datagrid" >   
          <Table celled>
            <Table.Header>
            <Table.Row>
              {this.props.columnheadings.map((columnheading, i) =><Table.HeaderCell key={this.props.fieldnames[i]} sorted={this.props.fieldnames[i]===sortcolumn ? sortdirection : null} onClick={this.handleSort(this.props.fieldnames[i])} >{columnheading}</Table.HeaderCell>)}
              {this.props.linefunctions && <Table.HeaderCell/>}
            </Table.Row>
            </Table.Header>
            <Table.Body>
            {this.state.results.map((row, i) =>
            {
              var groupkey="";
              var rowspan=1;
             return <Table.Row key={i}>
                {this.props.fieldnames.map((fieldname, j) =>
                {
                  if (this.props.groupby.indexOf(fieldname)>-1)
                  {
                    groupkey+='_'+row[fieldname].toString()
                    rowspan=this.state.groupcounts[groupkey]
                  }
                  if(rowspan>0)
                  {
                  if (!(groupkey+'_'+fieldname in fieldsdisplayed))
                  {
                    fieldsdisplayed[groupkey+'_'+fieldname]=true
                    return <Table.Cell key={j} rowSpan={rowspan}>{this.fieldValue(fieldname, row)}</Table.Cell>
                  }
                }
                else
                return <Table.Cell key={j} >{this.fieldValue(fieldname, row)}</Table.Cell>
                  
                }
                  
                )}
                {this.props.linefunctions && this.props.linefunctions.map(linefunction=>{
                  return <Table.Cell><a onClick={()=>{this.executeLineFunction(linefunction, row)}}>{linefunction.text}</a></Table.Cell>
                })}
                
              </Table.Row>
            }
        )}
            </Table.Body>
            
          </Table>
          </Segment>
          </div>
          
      );
    }
  }