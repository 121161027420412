import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import FileImport from "./containers/FileImport.js";
import PreAuthorisation from "./containers/PreAuthorisation.js";
import Login from "./containers/Login";
import Home from "./containers/Home";
import Report from "./containers/Report.js";
import Downloads from "./containers/Downloads.js";
import VersionPage from "./containers/VersionPage"
import config from "./config";
import { formatDate, JSONDate } from "./libs/sharedFunctions";


const today=new Date();
const fileImportManualFunctions={
  '': 
  [
      {function: 'addentity', parameters: [{name: 'New Customer', sites: [{name: 'New Site', products: []}], accounts: [{name: 'New Account', directdebits: [{accountname: 'New Account'}]}], contacts: [{lastname: 'New Contact'}], contracts: [{startdate: JSONDate(today)}]}]}, 
  ], 
  'customer': 
  [
     {text: 'Add Site', icon: 'add', function: 'addsubentity', parameters: ['sites', {name: 'New Site', products: []}]}, 
     {text: 'Add Contact', icon: 'add', function: 'addsubentity', parameters: ['contacts', {firstname: 'New', lastname: 'Contact'}]}, 
  ], 
  'site': 
  [
     {text: 'Copy Customer', icon: 'add', function: 'copydetails', parameters: ['customer', ['name', 'subbuildingname', 'buildingname', 'buildingnumber', 'dependentthoroughfare', 'thoroughfare', 'doubledependentlocality', 'dependentlocality', 'posttown', 'county', 'postcode']]}, 
     {text: 'Add Electricity', icon: 'add', function: 'addsubentity', parameters: ['products', {utilityid: 1, elecmeterpoints: []}]}, 
     {text: 'Add Gas', icon: 'add', function: 'addsubentity', parameters: ['products', {utilityid: 2, gasmeterpoints: []}]}, 
  ], 
  'account': 
  [
     {text: 'Copy Customer', icon: 'add', function: 'copydetails', parameters: ['customer', ['name', 'subbuildingname', 'buildingname', 'buildingnumber', 'dependentthoroughfare', 'thoroughfare', 'doubledependentlocality', 'dependentlocality', 'posttown', 'county', 'postcode']]}, 
  ], 

  'product': 
  [
     {condition: 'utilityid=1', text: 'Add Meter Point', icon: 'add', function: 'addsubentity', parameters: ['elecmeterpoints', {prices: {'Unit Rate': null, 'Day Rate': null, 'Night Rate': null, 'Evening / Weekend Rate': null, 'Standing Charge': null, 'Availability Charge': null}}]}, 
     {condition: 'utilityid=2', text: 'Add Meter Point', icon: 'add', function: 'addsubentity', parameters: ['gasmeterpoints', {prices: {'Unit Rate': null, 'Standing Charge': null}}]}, 
  ]
} 


export default class Routes extends Component {



  constructor(props) {
    super(props);
  
    this.state = {

      isAuthenticated: false, 
      isAuthorised: true,       
      userName: ""
    };
  }
  render() {

    const today=new Date()
    var dminus7 = new Date();
    dminus7.setDate(today.getDate() - 7)
    var dminus30 = new Date();
    dminus30.setDate(today.getDate() - 30)
  
  
  const manualUploadProducts=Object.keys(this.props.tpi.products).filter(productkey=>{return this.props.tpi.products[productkey].manualuploadformat});
  var manualUploadProduct;
  var manualUploadFormat;
  if (manualUploadProducts.length>0)
  {
    manualUploadProduct=manualUploadProducts[0]
    manualUploadFormat=this.props.tpi.products[manualUploadProduct].manualuploadformat
  }
  
  console.log(manualUploadProduct,manualUploadFormat )

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/login" exact component={Login} />
      <Route path="/preauthorisation" render={(routeProps)=><PreAuthorisation {...routeProps} tpiid={this.props.tpiid} tpi={this.props.tpi} userid={this.props.userid} productdefinitions={this.props.productdefinitions}/>} />
      <Route path="/import" render={(routeProps)=><FileImport {...routeProps} bucket={'seaglass.'+config.stage+'.sgserve.data'} folder='tpi.import' sourcetype={1} sourceid={this.props.tpiid} userid={this.props.userid} parameters={{tpiid: this.props.tpiid}} parameterfields={['commissionstructureid']} manualuploadproduct={manualUploadProduct} manualuploadformat={manualUploadFormat} manualfunctions={fileImportManualFunctions}   tpi={this.props.tpi} productdefinitions={this.props.productdefinitions} fileformats={this.props.fileformats} toplevelentity='customer' eSign={config.eSign}/>}/>
      <Route path="/contractreport" render={(routeProps)=><Report {...routeProps} 
      reportname='tpicontracts'
      reporttitle='Contracts Entered Report'
      reportbuttonlabel='Refresh'
      reportbuttonicon='refresh'
      loadingMessage='Preparing Report'
      indeterminate
      fieldnames={['customerid', 'customername', 'startdate', 'receiveddate', 'durationmonths', 'siteid', 'sitename', 'address', 'product', 'meterpointreference', 'effectivefrom', 'registrationstatus', 'creditcheckstatus', 'regcount', 'objcount', 'lastobjectiondate', 'initialpaymentstatus', 'employeeid']} 
      columnheadings={['Customer Id', 'Customer Name', 'Start Date', 'Received Date', 'Duration Months', 'Site Id', 'Site Name', 'Address', 'Product', 'Meter Point Reference', 'Registration Date', 'Registration Status', 'Credit Check Status', 'Registration Count', 'Objection Count', 'Last Objection Date', 'Payment Status', 'Employee Id']} 
      columnformats={{startdate: 'date', receiveddate: 'date', effectivefrom: 'date'}} 
      reportparameters={[
        {name: 'tpiid', value: this.props.tpiid}, 
        {name: 'fromdate', label: 'From Date', fieldtype: 'date', value: JSONDate(dminus7)}, 
        {name: 'todate', label: 'To Date', fieldtype: 'date', value: JSONDate(today)}, 
      ]}
      groupby={['customerid', 'siteid', 'product', 'meterpointreference']}
      linefunctions={[{'text': 'resend payment email', 'function': 'resendpaymentemail', 'fields': ['customerid'], 'successmessage': 'Payment email resent', 'failmessage': 'Failed to resend payment email'}]}
      />} />
      <Route path="/commissionreport" render={(routeProps)=><Report {...routeProps} 
      reportname='tpicommissions'
      reporttitle='Commission Due Report'
      reportbuttonlabel='Refresh'
      reportbuttonicon='refresh'
      fieldnames={['customerid', 'customername', 'siteid', 'sitename', 'product', 'meterpointreference', 'paymentid', 'paymentdescription', 'paymentstatus', 'duedate', 'amount', 'upliftvalue']} 
      columnheadings={['Customer Id', 'Customer Name', 'Site Id', 'Site Name', 'Product', 'Meter Point Reference', 'Payment Id', 'Payment Description', 'Payment Status', 'Due Date', 'Payment Amount', 'Uplift']} 
      columnformats={{duedate: 'date'}} 
      reportparameters={[
        {name: 'tpiid', value: this.props.tpiid}, 
        {name: 'fromdate', label: 'From Date', fieldtype: 'date', value: JSONDate(dminus30)}, 
        {name: 'todate', label: 'To Date', fieldtype: 'date', value: JSONDate(today)}, 
      ]}
      groupby={['customerid', 'siteid', 'product', 'meterpointreference', 'paymentid']}
      />} /> : <Route path="/" exact component={Home} />}
      {this.props.reports.indexOf(3)>-1 ? <Route path="/ecoespostcodesearch" render={(routeProps)=><Report {...routeProps} 
      reportname='ecoespostcodesearch'
      reporttitle='ECOES Post Code Search'
      reportbuttonlabel='Search'
      reportbuttonicon='search'
      loadingMessage='Searching'
      indeterminate={false}
      fieldnames={['mpancore', 'buildingname', 'buildingnumber', 'thoroughfare', 'dependentthoroughfare', 'dependentlocality', 'posttown']} 
      columnheadings={['Mpan Core', 'Building Name', 'Building Number', 'Street 1', 'Street 2', 'Locality', 'Town']} 
      columnformats={{}} 
      reportparameters={[
        {name: 'postcode', label: 'Post Code', fieldtype: 'varchar', value: ''}, 

      ]}
      groupby={['mpancore']}
      />} /> : <Route path="/" exact component={Home} />}
      {this.props.reports.indexOf(4)>-1 ? <Route path="/ecoesmpansearch" render={(routeProps)=><Report {...routeProps} 
      reportname='ecoesmpansearch'
      reporttitle='ECOES MPAN Search'
      reportbuttonlabel='Search'
      reportbuttonicon='search'
      loadingMessage='Searching'
      indeterminate={false}
      fieldnames={['gspgroupid', 'profileclass', 'metertimeswitchclass', 'linelossfactor', 'standardsettlementconfiguration', 'energisationstatus', 'meterserialnumber', 'metertype', 'measurementclass']} 
      columnheadings={['GSP Group', 'PC', 'MTC', 'LLFC', 'SSC', 'Energisation Status', 'Meter Serial Number', 'Meter Type', 'Measurement Class']} 
      columnformats={{}} 
      reportparameters={[
        {name: 'mpancore', label: 'MPAN Core', fieldtype: 'varchar', value: ''}, 

      ]}
      groupby={['gspgroupid']}
      />} /> : <Route path="/" exact component={Home} />}
      {this.props.reports.indexOf(7)>-1 ? <Route path="/xoserveaddresssearch" render={(routeProps)=><Report {...routeProps} 
      reportname='xoserveaddresssearch'
      reporttitle='Gas Address Search'
      reportbuttonlabel='Search'
      reportbuttonicon='search'
      loadingMessage='Searching'
      indeterminate={false}
      fieldnames={['mprn', 'house_no', 'house_name', 'sub_bulding_name', 'street', 'town', 'current_supplier_name', 'annual_quantity', 'end_user_category_code', 'msn']} 
      columnheadings={['MPRN', 'House No', 'House Name', 'Sub-Building Name', 'Street', 'Town', 'Current Supplier', 'AQ', 'EUC', 'Meter Serial Number']} 
      columnformats={{}} 
      reportparameters={[
        {name: 'postcode', label: 'Post Code', fieldtype: 'varchar', value: ''}, 
        {name: 'buildingnumber', label: 'Building Number', fieldtype: 'varchar', value: ''}, 
        {name: 'buildingname', label: 'Building Name', fieldtype: 'varchar', value: ''}, 
      ]}
      groupby={['mprn']}
      />} /> : <Route path="/" exact component={Home} />}
      {this.props.reports.indexOf(8)>-1 ? <Route path="/xoservemprnsearch" render={(routeProps)=><Report {...routeProps} 
      reportname='xoservemprnsearch'
      reporttitle='Gas MPRN Search'
      reportbuttonlabel='Search'
      reportbuttonicon='search'
      loadingMessage='Searching'
      indeterminate={false}
      fieldnames={['mprn', 'house_no', 'house_name', 'sub_bulding_name', 'street', 'town', 'current_supplier_name', 'annual_quantity', 'end_user_category_code', 'msn']} 
      columnheadings={['MPRN', 'House No', 'House Name', 'Sub-Building Name', 'Street', 'Town', 'Current Supplier', 'AQ', 'EUC', 'Meter Serial Number']} 
      columnformats={{}} 
      reportparameters={[
        {name: 'mprn', label: 'MPRN', fieldtype: 'varchar', value: ''}, 
      ]}
      groupby={['mprn']}
      />} /> : <Route path="/" exact component={Home} />}


      {/* <Route path="/test" exact component={Test} /> */}
      { /* Finally, catch all unmatched routes */ }
      {/* <Route component={NotFound} /> */}
      <Route path="/downloads" render={(routeProps)=><Downloads {...routeProps} tpi={this.props.tpi}/>} />
      <Route path="/info" render={(routeProps)=><VersionPage {...routeProps} user={this.props.user}/>} />
    </Switch>)
  }
}