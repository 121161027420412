  import React, { Component } from "react";
  import { Form, Input, Label } from 'semantic-ui-react'

  export default class TextBox extends Component {
  constructor(props)
  {
    super(props);
    this.onTextChange= this.onTextChange.bind(this);
    var value=this.props.value
    if(value===null) value=""
    this.state = {textValue: value , isError: false, errorText: ""};

  }

  

onTextChange(event)
{
  var newValue=event.target.value;
  const field=this.props.field;
  var isError=false
  var errorText=""


  //validation
  if(!field.nullable && newValue==="")
  {
    isError=true;
    errorText="Required"      
  }
  else if(newValue.length>field.length)
  {
    isError=true;
    errorText="Too long"      
  } 
  this.setState({textValue: newValue, isError: isError, errorText: errorText})
  this.props.onChange(this.props.fieldname, this.props.field, newValue, !isError)
}

    render() {
      const value=this.props.value;
      const showlabel=this.props.showlabel;
      const inline=this.props.inline;
      const showError=this.state.isError;
      var style='text'
      if ('style' in this.props.field) style=this.props.field.style
      if (style==='textarea')
      {
        return (
          <Form.Field required={!this.props.field.nullable}>
          {showlabel && <label>{this.props.field.label}</label>}
          <Form.TextArea placeholder={this.props.field.label} value={this.state.textValue} onChange={this.onTextChange} disabled={!this.props.enabled}/>
          {showError && <Label pointing>{this.state.errorText}</Label>}
          </Form.Field>)
      }
      else {
        return (
          <Form.Field required={!this.props.field.nullable}>
          {showlabel && <label>{this.props.field.label}</label>}
          {inline ? (<Form.Input inline type={style} placeholder={this.props.field.label} value={this.state.textValue} onChange={this.onTextChange} disabled={!this.props.enabled}/>)
          : (<Form.Input  type={style} placeholder={this.props.field.label} value={this.state.textValue} onChange={this.onTextChange} disabled={!this.props.enabled}/>)}
          {showError && <Label pointing>{this.state.errorText}</Label>}
          </Form.Field>
       )
      }
        
      }

      }
  