import React, { Component } from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {displayLoader: false};

    this.enableLoader = this.enableLoader.bind(this);
    this.timer = setTimeout(this.enableLoader, 333);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableLoader() {
    this.setState({displayLoader: true});
  }

  render() {
    const {displayLoader} = this.state;
    const loadingMessage = this.props.loadingMessage;
    const indeterminate = this.props.indeterminate;

    if (!displayLoader) {
      return null;
    }
    return (
      <Segment padded='very'>
          <Dimmer active inverted >
          <Loader size='large' indeterminate={indeterminate ? true : false}>{loadingMessage ? loadingMessage : 'Loading Page'}</Loader>
        </Dimmer>
      </Segment>
    );
  }
}

export default LoadingPage;