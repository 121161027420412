import React, { Component } from 'react';
import { Menu, Icon, Divider, Dropdown, Modal, Button, Header} from 'semantic-ui-react'
import './App.css';
import Routes from "./Routes.js";
import Login from "./containers/Login.js";
// import  { invokeApig } from "./libs/awsLib";
import { Link, withRouter } from "react-router-dom";
import { API } from "aws-amplify";
import ErrorPage from "./containers/ErrorPage"
import config from "./config";
import { invokeApig } from './libs/awsLib';
import { Auth } from "aws-amplify";
import Cookies from 'universal-cookie';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false, 
      isAuthenticating: true,
      isAuthorised: true,       
      userid: null, 
      user: {reports: [], tpis: []}, 
      userName: "", 
      productdefinitions: {}, 
      tpi: {products: {}}, 
      fileformats: {}
      
    };
    
    this.handleLogout=this.handleLogout.bind(this);
   
    this.events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
    this.resetTimeout = this.resetTimeout.bind(this);
    
    for (var i in this.events) {window.addEventListener(this.events[i], this.resetTimeout);}
    this.setTimeout();
  }

  loadCookies(tpis)
  {
    const cookies=new Cookies();
    const tpiIdCookie=cookies.get('sgSign-tpiIdCookie');
    var tpi = tpis[0];
    var tpiid = tpis[0].id;
    var findtpi = null;
  
    if (tpiIdCookie) {
      findtpi = tpis.find(x => x.id === Number(tpiIdCookie))
      if (findtpi) {
        tpiid=Number(tpiIdCookie);
        tpi = findtpi;
      }
    }
    this.setState({tpi: tpi, tpiid: tpiid});
  }
  
  clearTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }
  
  setTimeout() {
    //time min*seconds*milliseconds
    this.logoutTimeout = setTimeout(this.handleLogout, 30*60*1000);
  }
  
  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }
    
  async componentDidMount()
  {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      console.log(e)
      this.setState({ isAuthenticating: false });
    }
    // this.setState({ isAuthenticating: false });
    // console.log(process.env.REACT_APP_BRANDING);
    // const icPatronChat=require('./libs/icPatronChat.js')
    // icPatronChat.init({serverHost:'https://home-e32.niceincontact.com',bus_no:4599065,poc:'46cac740-7597-4b30-accb-ed626c530b42',params:['FirstName','Last Name','first.last@company.com',555-555-5555]});
    
  }

  loadProductDefinitions = () => {
    invokeApig({path: "/lookups/productdefinition", method: "GET",  body: null}).then((result)=>{this.setState({productdefinitions: result.data})}).catch((err)=>{console.log(err)});      
  }

  loadFileFormats = () => {
    invokeApig({path: "/lookups/fileformat", method: "GET",  body: null}).then((result)=>{this.setState({fileformats: result.data})}).catch((err)=>{console.log(err)});      
  }

  async handleLogout(event) {
    await Auth.signOut();
    this.setState({isAuthenticated: false, isAuthorised: false, isAuthenticating: false})
  }

  userHasAuthenticated = (authenticated) => {

    // get user details
    API.get('api', '/api/users/0', {response: true}).then((results)=>{
      if (results.data.applications.indexOf(config.applicationid)>-1)
      {
        const tpis=results.data.tpis;
        if (tpis.length>0)
          {
            this.loadCookies(tpis);
            this.setState({userid: results.data.id, userName: results.data.firstname + ' ' + results.data.lastname, user: results.data, isAuthenticated: true, isAuthorised: true, isAuthenticating: false} );
            this.loadProductDefinitions();
            this.loadFileFormats();
          }
        else
        this.setState({isAuthenticated: true, isAuthorised: false, isAuthenticating: false} )
      }
      else {this.setState({isAuthenticated: true, isAuthorised: false, isAuthenticating: false} )}
      
    })
  }

  compareObjects(object1, object2, key) {
    const obj1 = object1[key].toUpperCase()
    const obj2 = object2[key].toUpperCase()
  
    if (obj1 < obj2) {
      return -1
    }
    if (obj1 > obj2) {
      return 1
    }
    return 0
  }

  updateTPI(data) {
    this.setState({tpi: data.value, tpiid: data.value.id});
    const cookies=new Cookies();
    cookies.set('sgSign-tpiIdCookie', data.value.id, { path: '/' });
  }

  render() {
    const topLevelPath=window.location.pathname.split('/')[1]
    var tpioptions=this.state.user.tpis.map(tpi=>{return {key: tpi.id, value: tpi, text: tpi.name}})
    tpioptions.sort((tpi1, tpi2) => {
      return this.compareObjects(tpi1, tpi2, 'text')
    })
    var preauthorisation=false;
    Object.keys(this.state.tpi.products).forEach(productKey=>{if (productKey in this.state.productdefinitions && this.state.productdefinitions[productKey].requirepreauthorisation) {preauthorisation=true}; })
    return (
      !this.state.isAuthenticating 
      ?
      this.state.isAuthenticated
      ?
      this.state.isAuthorised  
      ?
        <div> 
        <Menu style={{marginBottom: "0px"}} secondary icon='labeled'>
        <div style={{width: "225px"}}><img src={require('./images/'+process.env.REACT_APP_BRANDING+'-logo.png')}/></div>
        <Link to='/'><Menu.Item active={topLevelPath===''} className='seaglassblue topmenu'><Icon name='home'/>Home</Menu.Item></Link>
        {preauthorisation && <Link to='/preauthorisation'><Menu.Item active={topLevelPath==='preauthorisation'} className='seaglassblue topmenu'><Icon name='check'/>Pre-Qualifier</Menu.Item></Link>}
        <Link to='/import'><Menu.Item active={topLevelPath==='import'} className='seaglassblue topmenu'><Icon name='upload'/>Contract Import</Menu.Item></Link>
        {this.state.user.reports.indexOf(1)>-1 ? <Link to='/contractreport'><Menu.Item active={topLevelPath==='contractreport'} className='seaglassblue topmenu'><Icon name='handshake'/>Contract Report</Menu.Item></Link> : null}
        {this.state.user.reports.indexOf(2)>-1 ? <Link to='/commissionreport'><Menu.Item active={topLevelPath==='commissionreport'} className='seaglassblue topmenu'><Icon name='pound'/>Commission Report</Menu.Item></Link> : null}
        {this.state.user.reports.indexOf(3)>-1 ? <Link to='/ecoespostcodesearch'><Menu.Item active={topLevelPath==='ecoespostcodesearch'} className='seaglassblue topmenu'><Icon name='search'/>ECOES Post Code Search</Menu.Item></Link> : null}
        {this.state.user.reports.indexOf(4)>-1 ? <Link to='/ecoesmpansearch'><Menu.Item active={topLevelPath==='ecoesmpansearch'} className='seaglassblue topmenu'><Icon name='search'/>ECOES MPAN Search</Menu.Item></Link> : null}
        {this.state.user.reports.indexOf(7)>-1 ? <Link to='/xoserveaddresssearch'><Menu.Item active={topLevelPath==='xoserveraddresssearch'} className='seaglassblue topmenu'><Icon name='search'/>Gas Address Search</Menu.Item></Link> : null}
        {this.state.user.reports.indexOf(8)>-1 ? <Link to='/xoservemprnsearch'><Menu.Item active={topLevelPath==='xoservemprnsearch'} className='seaglassblue topmenu'><Icon name='search'/>Gas MPRN Search</Menu.Item></Link> : null}

        <Link to='/downloads'><Menu.Item active={topLevelPath==='downloads'} className='seaglassblue topmenu'><Icon name='download'/>Downloads</Menu.Item></Link>
        <div className="right menu">
          <Menu.Item className="seaglassblue topmenu"><i aria-hidden="true" className="user icon"></i>{this.state.userName}</Menu.Item>
          <Menu.Item className="seaglassblue"><i aria-hidden="true" className="building icon"></i><Dropdown options={tpioptions} value={this.state.tpi} onChange={(event, data)=>{this.updateTPI(data)} }/></Menu.Item>
          {/* <Link to='/info'><Menu.Item active={topLevelPath==='info'} className='seaglassblue topmenu'><Icon name='info'/>Information</Menu.Item></Link> */}
          <Menu.Item><a className="seaglassblue item" onClick={this.handleLogout}><i aria-hidden="true" className="close icon"/>Sign out</a></Menu.Item>
        </div>
        </Menu> 
{/* 
        <div className="ui blue inverted labeled icon menu">
          <img src={require('./images/logo blue.jpg')}/>
          <Link className="active item" to="/import"><i aria-hidden="true" className="upload icon"></i>Contract Import</Link>
          <Link className="active item" to="/contractreport"><i aria-hidden="true" className="handshake icon"></i>Contract Report</Link>
          <Link className="active item" to="/commissionreport"><i aria-hidden="true" className="pound icon"></i>Commission Report</Link>

          <div className="right menu">
          <a className="item"><i aria-hidden="true" className="user icon"></i>{this.state.userName}</a>
          <a className="item"><i aria-hidden="true" className="building icon"></i>{this.state.tpi.name}</a>
          <a className="item" onClick={()=>this.userHasAuthenticated(false)}><i aria-hidden="true" className="close icon"/>Sign out</a>
          </div>
        </div> */}


        <div className="main">
        {/* <Grid> */}
        <Routes tpiid={this.state.tpiid} tpi={this.state.tpi} userid={this.state.userid} productdefinitions={this.state.productdefinitions} fileformats={this.state.fileformats}  reports={this.state.user.reports}/>
        {/* </Grid> */}
        </div>
        <Divider fitted/>
        <div className='poweredby'>Powered by <b><a href="https://www.seaglasscloud.com">Seaglass</a></b></div>
        </div>
      : <ErrorPage visible={true} messageHeader={'Authorisation Failed'} messageDetail={'You are not authorised to use this application'}/>
      : <Login isAuthenticated={this.state.isAuthenticated} onSuccess={()=>this.userHasAuthenticated(true)} />
      : null
    );
  }
}

export default App;
