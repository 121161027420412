import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from './serviceWorker';
import "./index.css";
// import './semantic.min.css';
import 'semantic-ui-css/semantic.min.css'

import Amplify from "aws-amplify";
import config from "./config";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
      endpoints: [
        {name: "api", endpoint: config.apiGateway.URL, region: config.apiGateway.REGION},   
        {name: "lookups", endpoint: config.apiGateway.URL, region: config.apiGateway.REGION}, 
        {name: "tpis", endpoint: config.apiGateway.URL, region: config.apiGateway.REGION}, 
        {name: "fileimports",endpoint: config.apiGateway.URL, region: config.apiGateway.REGION}, 
        {name: "entities", endpoint: config.apiGateway.URL, region: config.apiGateway.REGION}, 
        {name: "reports", endpoint: config.apiGateway.URL, region: config.apiGateway.REGION}, 
        {name: "storage", endpoint: config.apiGateway.URL, region: config.apiGateway.REGION}, 
        
    ]
  }, 
  Storage: {
    AWSS3: {
        bucket: 'seaglass.'+config.stage+'.sgserve.data', 
        region: config.aws.region
    }
  }
});

// intialise cache
sessionStorage.setItem('parentCache', '{}')
//sessionStorage.setItem('viewCache', '{}')

ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById("root")
  );
  serviceWorker.unregister();
