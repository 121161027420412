import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Container, Header, Icon } from 'semantic-ui-react'
import "./Downloads.css";
import config from "../config";
import  { downloadS3 } from "../libs/awsLib";

export default class Home extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      messages: []
    };
  
 }

 downloadFile(filename)
 {
   console.log(this.props.tpi.id)
   console.log(this.props.tpiid)
   downloadS3(config.s3documentsbucket, this.props.tpi.id+'/'+filename)
 }


  render() {

   
    return (
      <div className='fullpage'>
      <Container>
        <Header as='h2' className='seaglassblue'>Downloads</Header>
        <Table collapsing>
          <Table.Row><Table.Cell><Icon name='upload'/>Contract Upload Template</Table.Cell><Table.Cell><Button className='seaglassbutton' onClick={()=>this.downloadFile('Contract Upload v1.xlsx')}><Icon name='download'/>Download</Button></Table.Cell></Table.Row>
          {/* {'noncot' in this.props.tpi.products && <Table.Row><Table.Cell><Icon name='plug'/>Electricity Pricebook (Non CoT)</Table.Cell><Table.Cell><Button className='seaglassbutton' onClick={()=>this.downloadFile('Price Sheet Elec Non CoT.xlsx')}><Icon name='download'/>Download</Button></Table.Cell></Table.Row>}
          {'cot' in this.props.tpi.products && <Table.Row><Table.Cell><Icon name='plug'/>Electricity Pricebook (CoT)</Table.Cell><Table.Cell><Button className='seaglassbutton' onClick={()=>this.downloadFile('Price Sheet Elec CoT.xlsx')}><Icon name='download'/>Download</Button></Table.Cell></Table.Row>}
          {'noncot' in this.props.tpi.products && <Table.Row><Table.Cell><Icon name='fire'/>Gas Pricebook (Non CoT)</Table.Cell><Table.Cell><Button className='seaglassbutton' onClick={()=>this.downloadFile('Gas Non CoT Price Book.xlsx')}><Icon name='download'/>Download</Button></Table.Cell></Table.Row>}
          {'cot' in this.props.tpi.products && <Table.Row><Table.Cell><Icon name='fire'/>Gas Pricebook (CoT)</Table.Cell><Table.Cell><Button className='seaglassbutton' onClick={()=>this.downloadFile('Gas CoT Price Book.xlsx')}><Icon name='download'/>Download</Button></Table.Cell></Table.Row>} */}
          <Table.Row><Table.Cell><Icon name='pdf file'/>PDF Contract</Table.Cell><Table.Cell><Button className='seaglassbutton' onClick={()=>this.downloadFile('PDF Contract.pdf')}><Icon name='download'/>Download</Button></Table.Cell></Table.Row>
          <Table.Row><Table.Cell><Icon name='handshake'/>Terms and Conditions</Table.Cell><Table.Cell><Button className='seaglassbutton' onClick={()=>this.downloadFile('Terms and Conditions.pdf')}><Icon name='download'/>Download</Button></Table.Cell></Table.Row>
          <Table.Row><Table.Cell><Icon name='talk'/>Verbal Script</Table.Cell><Table.Cell><Button className='seaglassbutton' onClick={()=>this.downloadFile('Verbal Script.pdf')}><Icon name='download'/>Download</Button></Table.Cell></Table.Row>

        </Table>
      </Container>
      </div>
    );
  }
}
