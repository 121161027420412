import React, { Component } from "react";
import { Form, Input, Label, Segment, Header, TextArea, List, Button, Modal, Divider, Message, Icon, Grid } from 'semantic-ui-react'
import TextBox from "./TextBox"
import config from "../config";
import Select from "semantic-ui-react/dist/commonjs/addons/Select/Select";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { invokeApig } from "../libs/awsLib";

export default class AddressBox extends Component {
constructor(props)
{
  super(props);
  this.state = {editAddress: false, addresses: [], invalidAddress: false, validationErrors: [], label: "", subbuildingname: "", buildingname: "", buildingnumber: "", dependentthoroughfare: "", thoroughfare: "", doubledependentlocality: "", dependentlocality: "", posttown: "", county: "", postcode: "", searchpostcode: "", searchComplete: false};
  // this.setDataValue = this.setDataValue.bind(this);
  this.findAddresses = this.findAddresses.bind(this);
  this.selectAddress=this.selectAddress.bind(this);
  this.setAddressValue=this.setAddressValue.bind(this);
  this.editModeOpen = this.editModeOpen.bind(this);
  this.editModeClose = this.editModeClose.bind(this);
  this.addressChange=this.addressChange.bind(this);
  this.saveAddress=this.saveAddress.bind(this);
  this.setPostCode=this.setPostCode.bind(this);
}

// setDataValue(fieldname, field, value, valid=true)
// {
//  // set data
//  const fieldprefix=''
//  if ('fieldprefix' in this.props)
//  {
//   fieldprefix=this.props.fieldprefix;
//  } 
//   this.setState({[fieldname]: value});
//   this.props.onChange(fieldprefix + fieldname, field, value, valid);

// }

setAddressValue(event)
{
 // set data
 console.log(event) 
 //this.props.onChange(fieldname, field, value, valid);

}

findAddresses()
{
  // event.preventDefault();
  var url = new URL("https://"+config.getAddress.URL+"/find/"+this.state.searchpostcode);
  url.searchParams.append("api-key", config.getAddress.APIKey);
  url.searchParams.append("expand", true);
  fetch(url).then(response=>response.json()).then(addresses=>{
    if ('addresses' in addresses)
    {
      addresses.addresses.forEach(address=>{address.postcode=addresses.postcode});
      this.setState({addresses: addresses.addresses, searchComplete: true});
    }
    else
    this.setState({addresses: [], searchComplete: true})
    // console.log(addresses);
  }).catch(err=>{console.log(err)})
  // var idealPostcodes=require("ideal-postcodes")(config.idealPostcodes.apiKey)
  // idealPostcodes.lookupAddress(this.state.postcode, (error, addresses)=>{this.setState({addresses: addresses.result.hits})})
}

async componentDidMount() {
  const nulltoemptystr= (str)=>{return str===null ? "" : str};
  if ('loadfromentity' in this.props)
    this.loadFromEntity(this.props.entityname, this.props.entityinstanceid)
  
  this.setState({subbuildingname: nulltoemptystr(this.props.subbuildingname), buildingname: nulltoemptystr(this.props.buildingname),  buildingnumber: nulltoemptystr(this.props.buildingnumber), dependentthoroughfare: nulltoemptystr(this.props.dependentthoroughfare), thoroughfare: nulltoemptystr(this.props.thoroughfare), doubledependentlocality: nulltoemptystr(this.props.doubledependentlocality), dependentlocality: nulltoemptystr(this.props.dependentlocality), posttown: nulltoemptystr(this.props.posttown), county: nulltoemptystr(this.props.county),  postcode: nulltoemptystr(this.props.postcode), subbuildingname: nulltoemptystr(this.props.subbuildingname), buildingname: nulltoemptystr(this.props.buildingname), buildingnumber: nulltoemptystr(this.props.buildingnumber), dependentthoroughfare: nulltoemptystr(this.props.dependentthoroughfare), thoroughfare: nulltoemptystr(this.props.thoroughfare), doubledependentlocality: nulltoemptystr(this.props.doubledependentlocality), dependentlocality: nulltoemptystr(this.props.dependentlocality), posttown: nulltoemptystr(this.props.posttown), county: nulltoemptystr(this.props.county),  postcode: nulltoemptystr(this.props.postcode)})
  this.validateAddress(nulltoemptystr(this.props.buildingname), nulltoemptystr(this.props.buildingnumber), nulltoemptystr(this.props.thoroughfare), nulltoemptystr(this.props.posttown), nulltoemptystr(this.props.postcode))
}

componentWillReceiveProps(newProps)
{
  const nulltoemptystr= (str)=>{return str===null ? "" : str};
  if (newProps.subbuildingname!==this.props.subbuildingname || newProps.buildingname!==this.props.buildingname || newProps.buildingnumber!==this.props.buildingnumber || newProps.thoroughfare!==this.props.thoroughfare || newProps.dependentthoroughfare!==this.props.dependentthoroughfare || newProps.doubledependentlocality!==this.props.doubledependentlocality || newProps.dependentlocality!==this.props.dependentlocality || newProps.posttown!==this.props.posttown || newProps.county!==this.props.county  || newProps.postcode!==this.props.postcode)
  {
    this.setState({subbuildingname: nulltoemptystr(newProps.subbuildingname), buildingname: nulltoemptystr(newProps.buildingname),  buildingnumber: nulltoemptystr(newProps.buildingnumber), dependentthoroughfare: nulltoemptystr(newProps.dependentthoroughfare), thoroughfare: nulltoemptystr(newProps.thoroughfare), doubledependentlocality: nulltoemptystr(newProps.doubledependentlocality), dependentlocality: nulltoemptystr(newProps.dependentlocality), posttown: nulltoemptystr(newProps.posttown), county: nulltoemptystr(newProps.county),  postcode: nulltoemptystr(newProps.postcode), addresses: [], searchpostcode: ""});
    this.validateAddress(nulltoemptystr(newProps.buildingname), nulltoemptystr(newProps.buildingnumber), nulltoemptystr(newProps.thoroughfare), nulltoemptystr(newProps.posttown), nulltoemptystr(newProps.postcode));
  }
  if ('loadfromentity' in newProps)
    if (this.props.entityname!==newProps.entityname || this.props.entityinstanceid!==newProps.entityinstanceid)
      this.loadFromEntity(newProps.entityname, newProps.entityinstanceid);

}

selectAddress(event, data)
{
  console.log(data.value);
  if (data.value)
  {
  const address=this.state.addresses[data.value];
  this.setState({subbuildingname: address.sub_building_name, buildingname: address.building_name, buildingnumber: address.building_number, dependentthoroughfare: address.dependant_thoroughfare, thoroughfare: address.thoroughfare, dependentlocality: address.dependant_locality, doubledependentlocality: address.double_dependant_locality, posttown: address.town_or_city, county: address.county, postcode: address.postcode})
  this.validateAddress(address.building_number, address.building_name, address.thoroughfare, address.town_or_city, address.postcode)
  }
}

loadFromEntity(entityname, entityinstanceid) 
{
  invokeApig({path: "/api/" + entityname + 's/' +entityinstanceid, method: "GET",  body: null}).then(result=>{
    if (result.status===200)
    {
      this.setState({subbuildingname: result.data.subbuildingname, buildingname: result.data.buildingname, buildingnumber: result.data.buildingnumber, dependentthoroughfare: result.data.dependentthoroughfare, thoroughfare: result.data.thoroughfare, dependentlocality: result.data.dependentlocality, doubledependentlocality: result.data.doubledependentlocality, posttown: result.data.posttown, county: result.data.county, postcode: result.data.postcode});
      this.validateAddress(result.data.buildingnumber, result.data.buildingname, result.data.thoroughfare, result.data.posttown, result.data.postcode)
      //write back to parent
      this.props.onChange(result.data.subbuildingname, result.data.buildingname, result.data.buildingnumber, result.data.dependentthoroughfare, result.data.thoroughfare, result.data.doubledependentlocality, result.data.dependentlocality, result.data.posttown, result.data.county, result.data.postcode, this.props.fieldprefix, true, this.props.fieldname)
    }
  });

}

addressChange(event, data)
{
    const fieldname="" + data.placeholder.toLowerCase().replace(/ /g, "")
    console.log(fieldname, data.value)
    this.setState({[fieldname]: data.value});

    console.log(fieldname, data.value)
    
    const postcode=(fieldname==="postcode") ? data.value : this.state.postcode;
    const thoroughfare=(fieldname==="thoroughfare") ? data.value : this.state.thoroughfare;
    const buildingname=(fieldname==="buildingname") ?data.value : this.state.buildingname;
    const buildingnumber=(fieldname==="buildingnumber") ? data.value : this.state.buildingnumber;
    const posttown=(fieldname==="posttown") ? data.value : this.state.posttown;

    this.validateAddress(buildingnumber, buildingname, thoroughfare, posttown, postcode)


}

validateAddress(buildingnumber, buildingname, thoroughfare, posttown, postcode)
{
  var errors=[];
  console.log(postcode)
  if(!postcode || postcode.length===0) errors.push("Post code must be entered")
  else if (!postcode.includes(' '))
  errors.push("Post code must contain a space")
  else if (postcode.length>8)
  errors.push("Post code cannot have more than 8 characters")
  else if (postcode!==postcode.toUpperCase())
  errors.push("Post code must be upper case")

  if ((buildingname==undefined ? true : buildingname.length===0) && (buildingnumber==undefined ? true : buildingnumber.length===0))
    errors.push("Either building name or number must be entered")
  
  if (buildingnumber && buildingnumber.length>4)
    errors.push("Building number cannot have more than 4 characters")

  if(!thoroughfare || thoroughfare.length===0) errors.push("Thoroughfare must be entered")       
  if(!posttown || posttown.length===0) errors.push("Town must be entered")

  this.setState({invalidAddress: (errors.length>0), validationErrors: errors})

}

saveAddress()
{
  var fieldprefix=''
  if ('fieldprefix' in this.props)
  {
   fieldprefix=this.props.fieldprefix;
  } 

  // this.setState({subbuildingname: this.state.subbuildingname, buildingname: this.state.buildingname, buildingnumber: this.state.buildingnumber, dependentthoroughfare: this.state.dependentthoroughfare, thoroughfare: this.state.thoroughfare, doubledependentlocality: this.state.doubledependentlocality, dependentlocality: this.state.dependentlocality, posttown: this.state.posttown, county: this.state.county , postcode: this.state.postcode})    
  this.props.onChange(this.state.subbuildingname, this.state.buildingname, this.state.buildingnumber, this.state.dependentthoroughfare, this.state.thoroughfare, this.state.doubledependentlocality, this.state.dependentlocality, this.state.posttown, this.state.county, this.state.postcode, fieldprefix, true, this.props.fieldname)
  
}
editModeOpen() {this.setState({editAddress: true})}
editModeClose() {this.setState({editAddress: false})}
setPostCode(fieldname, field, value, valid=true)
{
  this.setState({[fieldname]: value})
}

  render() {

    var addressLines=[]
    if(this.props.subbuildingname && this.props.subbuildingname.length>0) addressLines.push(this.props.subbuildingname);
    if(this.props.buildingname && this.props.buildingname.length>0) addressLines.push(this.props.buildingname);
    
    if(this.props.buildingnumber && this.props.buildingnumber.length>0) 
    {
      if(this.props.dependentthoroughfare && this.props.dependentthoroughfare.length>0)
        {
          addressLines.push(this.props.buildingnumber + ' ' + this.props.dependentthoroughfare);
          if(this.props.thoroughfare && this.props.thoroughfare.length>0) addressLines.push(this.props.thoroughfare);
        }
        else
        {
          addressLines.push(this.props.buildingnumber + ' ' + this.props.thoroughfare);
        }
    }
    else
    {
      addressLines.push(this.props.thoroughfare);
    }
    if(this.props.doubledependentlocality && this.props.doubledependentlocality.length>0) addressLines.push(this.props.doubledependentlocality);
    if(this.props.dependentlocality && this.props.dependentlocality.length>0) addressLines.push(this.props.dependentlocality);
    if(this.props.posttown && this.props.posttown.length>0) addressLines.push(this.props.posttown);
    if(this.props.county && this.props.county.length>0) addressLines.push(this.props.county);
    if(this.props.postcode && this.props.postcode.length>0) addressLines.push(this.props.postcode);

    const addressOptions=this.state.addresses===[] ? [] : this.state.addresses.map((address, index)=>{return ({key: index, value: index, text: address.formatted_address[0] + (address.formatted_address[1].length>0 ? ', ' + address.formatted_address[1] : "")+ (address.formatted_address[2].length>0 ? ', ' + address.formatted_address[2] : "")+ (address.formatted_address[3].length>0 ? ', ' + address.formatted_address[3] : "")+ (address.formatted_address[4].length>0 ? ', ' + address.formatted_address[4] : "")})});
    const inlineModalStyle = {
      modal : {
        marginTop: '250px !important',
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    };
    return (
      <div>
        <Form.Field>
        <label>{this.props.label}</label>
        <List>
          {addressLines.map((line)=>{return <List.Item>{line}</List.Item>})}
        </List> 
        </Form.Field>
        <Button className='seaglasssecondarybutton' icon onClick={this.editModeOpen} disabled={!this.props.enabled}><Icon name='edit' />edit</Button>
        <Modal  style={inlineModalStyle.modal} size='tiny' open={this.state.editAddress}>
        <Modal.Header>Edit Address</Modal.Header>
        <Modal.Content>
        <Form>
        <Divider horizontal>Look up address</Divider>
        <TextBox fieldname="searchpostcode" field={({label: 'Post Code', nullable: true, length: 8})} value={this.state.searchpostcode} showlabel={false} onChange={this.setPostCode} enabled={true}/>
        <Button className='seaglasssecondarybutton' onClick={this.findAddresses}>Find Addresses</Button>
        <Form.Field>
        <label>Select address:</label>
        <Select options={addressOptions} onChange={this.selectAddress} />
        {this.state.searchComplete && <Label basic>{this.state.addresses.length===1 ? "1 address found" : this.state.addresses.length===0 ? 'No addresses found' : this.state.addresses.length + ' addresses found'}</Label>}
        </Form.Field>
        <Divider horizontal>Or enter manually</Divider>
        <Form.Field>
        <Input placeholder="Sub Building Name" value={this.state.subbuildingname} onChange={this.addressChange}/>
        <Input placeholder="Building Name" value={this.state.buildingname} onChange={this.addressChange}/>
        <Input placeholder="Building Number" value={this.state.buildingnumber} onChange={this.addressChange}  maxLength={4}/>
        <Input placeholder="Dependent Thoroughfare" value={this.state.dependentthoroughfare} onChange={this.addressChange} />
        <Input placeholder="Thoroughfare" value={this.state.thoroughfare} onChange={this.addressChange} />
        <Input placeholder="Double Dependent Locality" value={this.state.doubledependentlocality} onChange={this.addressChange} />
        <Input placeholder="Dependent Locality" value={this.state.dependentlocality} onChange={this.addressChange} />
        <Input placeholder="Post Town" value={this.state.posttown} onChange={this.addressChange} />
        <Input placeholder="County" value={this.state.county} onChange={this.addressChange} />
        <Input placeholder="Post Code" value={this.state.postcode} onChange={this.addressChange}  maxLength={8}/>
        </Form.Field>
        <Message
        error
        visible={this.state.invalidAddress}
        header="Address validation failed"
        list={this.state.validationErrors}
      />
        <Button className='seaglassbutton' disabled={this.state.invalidAddress} onClick={this.saveAddress}>Save</Button>
        <Button className='seaglassbutton' onClick={this.editModeClose}>Close</Button>
        </Form>
        </Modal.Content>
        </Modal>
      </div>

    );
  }
}