import React, { Component } from "react";
import { Menu, Label, Icon, Button } from "semantic-ui-react"
import { Link } from "react-router-dom";

import "./LeftMenu.css"

export default class LeftMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      activeItem: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.menuName) {
      if (nextProps.menuName !== this.props.menuName) {
        this.setState({ activeItem: null })
      }  
    }
  }

  handleRefreshClick = () => {
    this.setState({ activeItem: null })
    this.props.onRefresh()
  }

  handleItemClick = (itemName) => {
    this.setState({ activeItem: itemName })
    if (this.props.itemClick) {this.props.itemClick(itemName)}
  }

  renderHeader() {
    return (
      <Menu.Item header className="leftMenuHeader">
        {this.props.header}
        {(this.props.onRefresh) ? <Button size="mini" icon="refresh" floated="right" circular onClick={this.handleRefreshClick} /> : null}
      </Menu.Item>
    )
  }

  renderMenuItem(item, counts) {
    const icon = (typeof item === 'string') ? null : (item.icon) ? item.icon : null
    const itemName = (typeof item === 'string') ? item : item.name
    const activeItem = (this.state.activeItem) ? this.state.activeItem : this.props.activeItem
    const active = (activeItem === itemName)
    return(
      <Menu.Item onClick={() => this.handleItemClick(itemName)} active={active}><Icon name={icon} />{itemName}{this.renderCount(counts, itemName)}</Menu.Item>
    )
  }

  renderMenu(menu) {
    return(
      <>
        {(menu.header) ? <Menu.Item header>{menu.header}</Menu.Item> : null}
        {(menu.items) ? menu.items.map(item=>this.renderMenuItem(item, menu.counts)) : null}
      </>
    )
  }

  renderCount(counts, item) {
    if (counts) {
      if (counts[item]) {
        return(<Label circular color="teal" size="mini">{counts[item]}</Label>)
      }
    }
  }

  render() {
    const menus = (this.props.menu) ? this.props.menu : [{header: this.props.header, items: this.props.items}]
    return (
        <Menu fluid className="seaglassblueinverted leftmenu" inverted vertical>
            {(this.props.header || this.props.onRefresh) ? this.renderHeader() : null}
            {(menus) ? menus.map(menu=>this.renderMenu(menu)) : null}
        </Menu>
    )    
  }
}
