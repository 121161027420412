import React, { Component } from "react";
import "./Login.css";
import config from "../config";
import { Button, Form, Grid,  Image, Segment, Modal, Message } from 'semantic-ui-react'
// import {
//     CognitoUserPool,
//     AuthenticationDetails,
//     CognitoUser
//   } from "amazon-cognito-identity-js";
// import { authUser } from "../libs/awsLib";
  import { Auth } from "aws-amplify";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newpassword: "",
      repeatnewpassword: "", 
      success: false, 
      failure: false, 
      failurereason: ''
    };
  }

  validateForm() {
    return this.state.newpassword.length > 0 && this.state.repeatnewpassword.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    
    if(this.state.newpassword !==this.state.repeatnewpassword)
    {
      this.setState({failure: true, failurereason: 'Passwords do not match'})
    }
    else
    {
    try {
      // if (await authUser()) this.props.onSuccess();
      this.setState({success: false, failure: false })
      Auth.completeNewPassword(this.props.user, this.state.newpassword, this.props.user.challengeParam.requiredAttributes)
        .then(result=>{
        // this.setState({success: true})
        this.props.onSuccess()
        })
        .catch(error=>{
          this.setState({failure: true, failurereason: error.message})
          console.log(error)
        })

      
      
    } catch (e) {
      alert(e);
    }
  }
  }
  // login(email, password) {
  //   const userPool = new CognitoUserPool({
  //     UserPoolId: config.cognito.USER_POOL_ID,
  //     ClientId: config.cognito.APP_CLIENT_ID
  //   });
  //   const user = new CognitoUser({ Username: email, Pool: userPool });
  //   const authenticationData = { Username: email, Password: password };
  //   const authenticationDetails = new AuthenticationDetails(authenticationData);
  
  //   return new Promise((resolve, reject) =>
  //     user.authenticateUser(authenticationDetails, {
  //       onSuccess: result => resolve(),
  //       onFailure: err => reject(err)
  //     })
  //   );
  // }

  render() {

    const inlineModalStyle = {
      modal : {
        marginTop: '250px !important',
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    };
    return (
        <Modal style={inlineModalStyle.modal} open={this.props.visible}>
        <Modal.Header>Change Password</Modal.Header>
        <Modal.Content>
        {this.props.text}
        <Form size='large' onSubmit={this.handleSubmit}>
            <Form.Input
                id="newpassword"
                fluid
                icon='lock'
                iconPosition='left'
                placeholder='New Password'
                type='password'
                value={this.state.newpassword}
                onChange={this.handleChange}                
            />
            <Form.Input
                id="repeatnewpassword"
                fluid
                icon='lock'
                iconPosition='left'
                placeholder='Repeat New Password'
                type='password'
                value={this.state.repeatnewpassword}
                onChange={this.handleChange}                
            />
            
          <Button className='seaglassblue' fluid size='large' disabled={!this.validateForm()} type="submit">Change Password</Button>
          <Message negative hidden={!this.state.failure}>
          <Message.Header>Password update failed</Message.Header>
          {this.state.failurereason}
          </Message>          
        </Form>
        </Modal.Content>
        </Modal>
    );
  }
}