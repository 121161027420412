import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Message, Segment, Header, Image } from 'semantic-ui-react'
import "./Home.css";
import config from "../config";
import  { invokeApig } from "../libs/awsLib";
import _ from 'lodash';

// import Background from '../images/'+process.env.REACT_APP_BRANDING+'-logo-large.png';

export default class Home extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      messages: []
    };
  
  invokeApig({path: "/api/applicationmessages", method: "GET",  body: null, queryParams:['application='+config.applicationid]}).then((result)=>
  {
    var state={messages: _.sortBy(result.data, 'messageorder')}
    //set all messages to true initially
    result.data.map((message)=>{state['messagevisible_'+message.id]= true})
    this.setState(state)
  });
  
 }



  render() {

  
    return (
      <div className="applicationmessages">
      <div className="logobackground" style={{backgroundImage: "url('branding/"+process.env.REACT_APP_BRANDING+"/logo-large.png')"}}></div>
      <Container>
         {/* <Image  centered src={require('../images/Seaglass-logo-large.png')}/> */}
      {this.state.messages.filter((message)=>{return this.state['messagevisible_'+message.id]}).map((message)=>{
        return <Message size='huge'
          onDismiss={()=>{this.setState({['messagevisible_'+message.id]:false})}}
          icon={message.message.icon}
          header={message.message.header}
          content={message.message.content}
          list={message.message.list}

        />
      })}
      </Container>
        {/* <Notifications notifications={this.props.notifications} deleteFunction={this.props.deleteFunction}/> */}
      </div>
    );
  }
}