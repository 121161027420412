import React, { Component } from "react";
import { Message, Icon } from 'semantic-ui-react'
import './ErrorPage.css';

export default class ErrorPage extends Component {
// constructor(props)
// {
//   super(props);
// }

render() {
    return (
            
    <Message icon size='huge' >
        <Icon name='exclamation' />
        <Message.Content>
        <Message.Header>{this.props.messageHeader}</Message.Header>
        {this.props.messageDetail}
        </Message.Content>
    </Message>
    )
}
}