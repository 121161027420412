const versions = [
    {
        "label": "2020.3.1",
        "status": "Test",
        "segments": [
            {
                "label": "Version Information",
                "style": "text",
                "items": [
                    {"label": "Version", "value": "2020.3.1"},
                    {"label": "Application", "value": "sgSign"},
                    {"label": "Status", "value": "Test"},
                    {"label": "Release Type", "value": "Major"}, 
                    {"label": "Release Date", "value": "27/03/2020"}
                ]
            },
            {
                "label": "Release Notes",
                "style": "messages",
                "items": [
                    {"header": "CR1509 Max Commission uplift by broker", "detail": "Ability to configure events and messages"},
                    ]}, 
                ]
            }
        ]


export default versions