import React from "react";
import { Popup, List, Button } from "semantic-ui-react";
import ReactJson from 'react-json-view'
import XLSX from 'xlsx';

export function formatField(field, value, row=null)
{
    if("lookup" in field && "values" in field)
    {
        
        if ('filterfields' in field)
        {
            if(row)
            {
                const lookupkey=field.filterfields.map(fieldname=>{return row[fieldname]}).join('_')
                if (lookupkey in field.values)
                {
                    if (value in field.values[lookupkey])
                    {
                        return field.values[lookupkey][value]    
                    }
                }
            }
        }
        else
        {
            if(value in field.values)
            {
                return field.values[value];
            }
        }
        return ""   
    }
    switch(field['type'])
    {
        case "date":
            if(value) return formatDate(new Date(value));
            else return ""
        case "datetime":
            if(value) return formatDateTime(new Date(value));
            else return ""
        case "time":
            if(value) return formatTime(new Date(value));
            else return ""
        case "bit":
            if (value===true) return "True" 
            else if (value===false) return "False" 
            else return ""
        case "json":
            //if(value) return <Popup trigger={<Button icon='search'/>} wide><Popup.Header>Data Values</Popup.Header><Popup.Content><List>{Object.keys(value).map((key=>{if (value[key] !==null) return <List.Item>{key + " " + value[key]}</List.Item>}))}</List></Popup.Content></Popup>
            if(value) return <ReactJson src={value} name={false} collapsed={true} iconStyle="triangle" displayDataTypes={false} displayObjectSize={false} />
            else return ""
        default:
            return value
    }


}

export function formatDate(date) {
    var monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct",
      "Nov", "Dec"
    ];
  
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
  
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  export function formatTime(date) {
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
  
    return ('00' + hour.toString()).slice(-2) + ':' + ('00' + min.toString()).slice(-2) + ':' + ('00' + sec.toString()).slice(-2);
  }

  export function formatDateTime(date) {
    return formatDate(date) +' ' + formatTime(date);
  }

  export function formatAddress (addressObject)
  {

    var addressLines=[]
    if(addressObject.subbuildingname.length>0) addressLines.push(addressObject.subbuildingname);
    if(addressObject.buildingname.length>0) addressLines.push(addressObject.buildingname);
    
    if(addressObject.buildingnumber.length>0) 
    {
      if(addressObject.dependentthoroughfare.length>0)
        {
          addressLines.push(addressObject.buildingnumber + ' ' + addressObject.dependentthoroughfare);
          if(addressObject.thoroughfare.length>0) addressLines.push(addressObject.thoroughfare);
        }
        else
        {
          addressLines.push(addressObject.buildingnumber + ' ' + addressObject.thoroughfare);
        }
    }
    else
    {
      addressLines.push(addressObject.thoroughfare);
    }
    if(addressObject.doubledependentlocality.length>0) addressLines.push(addressObject.doubledependentlocality);
    if(addressObject.dependentlocality.length>0) addressLines.push(addressObject.dependentlocality);
    if(addressObject.posttown.length>0) addressLines.push(addressObject.posttown);
    if(addressObject.county.length>0) addressLines.push(addressObject.county);
    if(addressObject.postcode.length>0) addressLines.push(addressObject.postcode);
    return addressLines;

  }

  export function JSONDate(date) {
 
    var day = date.getDate();
    var month= date.getMonth()+1;
    var year = date.getFullYear();
  
    return year.toString()+'-'+('00'+month.toString()).slice(-2)+'-'+('00'+day.toString()).slice(-2);
  }
  
  export function JSONDateTime(date) {
 
    return JSONDate(date)+'T'+formatTime(date)
  }

  export function JSONMonth(date) {
 
    var month= date.getMonth()+1;
    var year = date.getFullYear();
  
    return year.toString()+'-'+('00'+month.toString()).slice(-2)
  }

  export function exporttocsv(headers, data, filename) {
    console.log(data)
    var csv = headers.join(',') + '\n';
    data.forEach(row=> {
            csv += row.join(',');
            csv += "\n";
    });
 
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
}

export function convertLookupListtoObject(lookuplist) {
    return lookuplist.reduce((obj, item) => {
        obj[item.value] = item.text
        return obj
      }, {})
}


export function exportToExcel(data, fieldnames, headers, title) {
    var sheetdata=[]
    sheetdata.push(headers);
    data.map(row=>{
        const sheetrow=fieldnames.map(fieldname=>{return row[fieldname]});
        sheetdata.push(sheetrow);
    })
    console.log(sheetdata)
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet(sheetdata);
    XLSX.utils.book_append_sheet(wb, ws, title);
    XLSX.writeFile(wb, title+'.xlsx');
}


export function deepCopy(object)
{
    return JSON.parse(JSON.stringify(object))
}



export function base64ToArrayBuffer(base64) {
    const bytes = new Uint8Array(base64.length);
    return bytes.map((byte, i) => base64.charCodeAt(i));
  };

export function getContentType(filename)
{
  if (filename.endsWith('.pdf'))
      return 'application/pdf'
  else if (filename.endsWith('.wav'))
      return 'audio/wav'
  else if (filename.endsWith('.mp3'))
      return 'audio/mpeg'        
  else if (filename.endsWith('.doc'))
      return 'application/msword'
  else if (filename.endsWith('.docx'))
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  else if (filename.endsWith('.xls'))
      return 'application/vnd.ms-excel'
  else if (filename.endsWith('.xlsx'))
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  else if (filename.endsWith('.txt'))
      return 'text/plain'
  else if (filename.endsWith('.html'))
      return 'text/html'
  else if (filename.endsWith('.gif'))
      return 'image/gif'
  else if (filename.endsWith('.jpg'))
      return 'image/jpeg'
  else if (filename.endsWith('.png'))
      return 'image/png'
}
