import { API } from "aws-amplify";
import AWS from "aws-sdk";
import config from "../config";
import { getContentType, base64ToArrayBuffer  } from "./sharedFunctions";


AWS.config.update({
  secretAccessKey: config.aws.secretAccessKey,
  accessKeyId: config.aws.accessKeyId,
  region: config.aws.region
});   

  export async function invokeApig({
    path,
    method = "GET",
    headers = {'Content-Type': 'application/json'},
    queryParams = {},
    body, 
    outputFormat='json'
  })
{
    const api=path.split('/')[1]
    console.log(method, path, body)
    if (method==='GET')
    {
      try
      {
      var result= await API.get(api, path, {response: true, headers: headers, queryStringParameters: queryParams, body: body })
      console.log(result)
      return result
      }
      catch(e)
      {
        console.log(e)
        if (e.response)
        {
          return e.response
        }
        else
        {
          return {status: 502, data: ['Unknown error']}
        }
        
      }
    }
    else if (method==='POST')
    {
      try
      {
      var result= await API.post(api, path, {response: true, headers: headers, queryStringParameters: queryParams, body: body })
      console.log(result)
      return result
      }
      catch(e)
      {
        console.log(e)
        if (e.response)
        {
          return e.response
        }
        else
        {
          return {status: 502, data: ['Unknown error']}
        }
      }
    }
    else if (method==='PUT')
    {
      try
      {
      var result= await API.put(api, path, {response: true, headers: headers, queryStringParameters: queryParams, body: body })
      console.log(result)
      return result
      }
      catch(e)
      {
        console.log(e)
        if (e.response)
        {
          return e.response
        }
        else
        {
          return {status: 502, data: ['Unknown error']}
        }
      }
    }
    else if (method==='DELETE')
    {
      try
      {
      var result= await API.del(api, path, {response: true, headers: headers, queryStringParameters: queryParams, body: body })
      console.log(result)
      return result
      }
      catch(e)
      {
        console.log(e)
        if (e.response)
        {
          return e.response
        }
        else
        {
          return {status: 502, data: ['Unknown error']}
        }
      }
    }    
  }


// export async function uploadS3(bucket, key, file)
// {
//   var s3 = new AWS.S3();
//   var params = {
//     Bucket: bucket,
//     Key: key,
//     Body: file
//   };

//   s3.putObject(params, function(err, data) {
//     if (err) 
//     {
//       console.log(err)
//       return false;
//     }
//     else     
//     {
//       console.log(data)
//       return true;
//     }

//   });

// }


// export async function uploadS3(bucket, key, file)
// {
//   var s3 = new AWS.S3();
//   var params = {
//     Bucket: bucket,
//     Key: key,
//     Body: file
//   };

//   s3.putObject(params, function(err, data) {
//     if (err) 
//     {
//       console.log(err)
//       return false;
//     }
//     else     
//     {
//       console.log(data)
//       return true;
//     }

//   });

// }

export async function uploadS3(bucket, key, file)
{
  return new Promise((resolve, reject)=>
  {

  invokeApig({path: "/storage/"+bucket+'/'+encodeURIComponent(key), method: "PUT",  body: null}).then((result)=>
  { 
    console.log(result)
    if (result.status===200)
    {
      fetch(result.data, {method: 'PUT', body: file}).then(response=>{resolve(response)}).catch(err=>{reject(err)})
      
    }
  }).catch(err=>{console.log(err)});
});
}

export async function uploadS3Folder(bucket, folder, key, file)
{
  return new Promise((resolve, reject)=>
  {

  invokeApig({path: "/storage/"+bucket+'/'+ folder+'/'+encodeURIComponent(key), method: "PUT",  body: null}).then((result)=>
  { 
    console.log(result)
    if (result.status===200)
    {
      fetch(result.data, {method: 'PUT', body: file}).then(response=>{resolve(response)}).catch(err=>{reject(err)})
      
    }
  }).catch(err=>{console.log(err)});
});
}


export async function downloadS3(bucket, key)
{
  
  return new Promise((resolve, reject)=>
  {

  
  invokeApig({path: "/storage/"+bucket+'/'+encodeURIComponent(key), method: "GET",  body: null}).then((result)=>
  { 
    console.log(result)
    if (result.status===200)
    {
      var link = document.createElement('a');
    link.href = result.data;
    link.download = key;
    window.open(link.href).focus();
    // link.click();        
    resolve();
    }
  }).catch(err=>{reject(err)});
});
}

export async function deleteS3(bucket, key)
{
  return new Promise((resolve, reject)=>
  {
  invokeApig({path: "/storage/"+bucket+'/'+encodeURIComponent(key), method: "DELETE",  body: null}).then((result)=>
  { 
    console.log(result)
    if (result.status===200)
    {
      resolve(result.data)
    }
    else
    {
      reject(result.data)
    }
  }).catch(err=>{reject(err)});
});

}

export async function listS3objects(bucket, prefix='')
{
  return new Promise((resolve, reject)=>
  {
  invokeApig({path: "/storage/"+bucket, method: "GET", queryParams: ['prefix='+encodeURIComponent(prefix)]}).then((result)=>
  { 
    console.log(result)
    if (result.status===200)
    {
      resolve(result.data)
    }
    else
    {
      reject(result.data)
    }
  }).catch(err=>{reject(err)});
});
}


// export async function uploadS3(key, file)
// {
//   console.log(key)
//   const result= await Storage.put(key, file)
//   console.log(result); 
//   return true
// }
  // .then (result => {console.log(result); return true}) // {key: "test.txt"}
  // .catch(err => {console.log(err); return false});
// }


// import AWS from "aws-sdk";
// import { CognitoUserPool } from "amazon-cognito-identity-js";
// import config from "../config";
// import sigV4Client from "./sigV4Client";

// export async function authUser() {
//   if (
//     AWS.config.credentials &&
//     Date.now() < AWS.config.credentials.expireTime - 60000
//   ) {
//     return true;
//   }

//   const currentUser = getCurrentUser();

//   if (currentUser === null) {
//     return false;
//   }

//   const userToken = await getUserToken(currentUser);

//   await getAwsCredentials(userToken);

//   return true;
// }

// function getUserToken(currentUser) {
//   return new Promise((resolve, reject) => {
//     currentUser.getSession(function(err, session) {
//       if (err) {
//         reject(err);
//         return;
//       }
//       resolve(session.getIdToken().getJwtToken());
//     });
//   });
// }

// function getCurrentUser() {
//   const userPool = new CognitoUserPool({
//     UserPoolId: config.cognito.USER_POOL_ID,
//     ClientId: config.cognito.APP_CLIENT_ID
//   });
//   return userPool.getCurrentUser();
// }

// function getAwsCredentials(userToken) {
//     const authenticator = `cognito-idp.${config.cognito
//       .REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;
  
//     AWS.config.update({ region: config.cognito.REGION });
  
//     AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//       IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
//       Logins: {
//         [authenticator]: userToken
//       }
//     });
  
//     return AWS.config.credentials.getPromise();
//   }

//   export async function invokeApig({
//     path,
//     method = "GET",
//     headers = {},
//     queryParams = {},
//     body, 
//     outputFormat='json'
//   }) {
//     if (!await authUser()) {
//       throw new Error("User is not logged in");
//     }
  
//     const signedRequest = sigV4Client
//       .newClient({
//         accessKey: AWS.config.credentials.accessKeyId,
//         secretKey: AWS.config.credentials.secretAccessKey,
//         sessionToken: AWS.config.credentials.sessionToken,
//         region: config.apiGateway.REGION,
//         endpoint: config.apiGateway.URL
//       })
//       .signRequest({
//         method,
//         path,
//         headers,
//         queryParams,
//         body
//       });
    
//     body = body ? JSON.stringify(body) : body;
//     headers = signedRequest.headers;
//     var results;
//     try {
//     results= await fetch(signedRequest.url, {
//       method,
//       headers,
//       body
//     });
//   console.log(results); 
//   } catch(e) {return {status: 418, body: ["An error occurred fetching the results - possible network error"]};}

//     const returndata=await results.json()

//     console.log({status: results.status, body: returndata})  
//     return {status: results.status, body: returndata};
//   }