import React, { Component } from "react";
import { Grid } from "semantic-ui-react"
import LeftMenu from "./LeftMenu"
import versions from "../config/version"
import Information from "./Information"

export default class VersionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      versionData: null,
      versionNumber: null,
      version: null,
      menuData: null
    }

  }

  async componentDidMount() {
    this.loadVersion()
  }

  loadVersion() {
    const menuData = []
    const menuSections = {}
    const versionData = {}
    var liveVersion = null
    for (var x in versions) {
      var version = versions[x]
      if (!menuSections[version.status]) {menuSections[version.status]={header: null, items: []}}
      menuSections[version.status].header = version.status
      menuSections[version.status].items.push(version.label)
      versionData[version.label] = version
      if (version.status=="Live") {liveVersion=version.label}
    }
    if (menuSections["Live"]) {menuData.push(menuSections["Live"])}
    for (var x in menuSections) {
      if (x!="Live") {menuData.push(menuSections[x])}
    }
    const versionNumber = (this.props.match.params.version) ? this.props.match.params.version : liveVersion
    version = versionData[versionNumber]
    this.setState({ menuData, versionData, version, versionNumber })
  }

  handleMenuClick = (versionNumber) => {
    this.setState({ version: this.state.versionData[versionNumber], versionNumber })
  }

  render() {
    return (
      <div className="App container">
        <Grid divided stretched className="toolGrid">
          <Grid.Row className="main" stretched>
            <Grid.Column width={3} stretched>
              <LeftMenu menu={this.state.menuData} itemClick={this.handleMenuClick} activeItem={this.state.versionNumber} />
            </Grid.Column>
            <Grid.Column width={12} stretched>
              {(this.state.version) ? <Information info={this.state.version} /> : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }

}
