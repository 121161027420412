import React, { Component } from "react";
import "./Login.css";
import config from "../config";
import QRCode from 'qrcode.react';
import { Button, Form, Grid,  Image, Segment, Message, Modal } from 'semantic-ui-react'
import ChangePassword from './ChangePassword'
// import {
//     CognitoUserPool,
//     AuthenticationDetails,
//     CognitoUser
//   } from "amazon-cognito-identity-js";
// import { authUser } from "../libs/awsLib";
import { Auth } from "aws-amplify";
// import Auth from "@aws-amplify/auth";  
import { min } from "lodash";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "", 
      loginfailed: false, 
      loginfailedmessage: '',
      changePassword: false, 
      user: null, 
      setupTOTP: false, 
      totpcode: null, 
      TOTP: null, 
      qrSource: '', 
      showMFAChallenge: false, 
      showSMSChallenge: false
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
  
    try {
      // if (await authUser()) this.props.onSuccess();
      this.setState({loginfailed: false})
      
      Auth.signIn(this.state.email.toLowerCase(), this.state.password)
        .then((result)=>{
          console.log(result)
          if('challengeName' in result)
            if(result.challengeName==='NEW_PASSWORD_REQUIRED')
            {
              this.setState({changePassword: true, user: result})
            }
            else if (result.challengeName==='SOFTWARE_TOKEN_MFA')
            {
              this.setState({showMFAChallenge: true, user: result})
            }
            else if (result.challengeName==='SMS_MFA')
            {
              this.setState({showSMSChallenge: true, user: result})
            }            
            else
            {
              console.log(result.challengeName)
            }
          else
          {
            this.props.onSuccess()
          }
        })
        .catch((error)=>{
          console.log(error)
          this.setState({loginfailed: true, loginfailedmessage: 'Invalid user name / email address'})

        })

      
      
    } catch (e) {
      alert(e);
    }
  }
  // login(email, password) {
  //   const userPool = new CognitoUserPool({
  //     UserPoolId: config.cognito.USER_POOL_ID,
  //     ClientId: config.cognito.APP_CLIENT_ID
  //   });
  //   const user = new CognitoUser({ Username: email, Pool: userPool });
  //   const authenticationData = { Username: email, Password: password };
  //   const authenticationDetails = new AuthenticationDetails(authenticationData);
  
  //   return new Promise((resolve, reject) =>
  //     user.authenticateUser(authenticationDetails, {
  //       onSuccess: result => resolve(),
  //       onFailure: err => reject(err)
  //     })
  //   );
  // }

  closeChangePassword=()=> {
    this.setState({changePassword: false});
  }

  submitTOTP()
  {
    Auth.confirmSignIn(this.state.user, this.state.TOTP, 'SOFTWARE_TOKEN_MFA').then(authoriseduser=>{
      sessionStorage.setItem("authenticated", "true");
      this.props.onSuccess();
    }).catch(error=>{
    this.setState({loginfailed: true, loginfailedmessage: 'Invalid one time password', showMFAChallenge: false})
    });
 
  }

  submitSMSCode()
  {
    Auth.confirmSignIn(this.state.user, this.state.SMSCode, 'SMS_MFA').then(authoriseduser=>{
      sessionStorage.setItem("authenticated", "true");
      this.props.onSuccess();
    }).catch(error=>{
    this.setState({loginfailed: true, loginfailedmessage: 'Invalid SMS Code', showSMSChallenge: false, processing: false})
    });
 
  }

  render() {
    const modalStyle={
      marginTop: '250px !important',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
    return (
      <div className="Login">
        <Image centered src={require('../images/'+process.env.REACT_APP_BRANDING+'-logo.png')} />
        {/* <Grid
      textAlign='center'
      style={{ height: '100%' }}
      verticalAlign='top'>
        <Grid.Column style={{ maxWidth: 450}}> */}

        <div className="loginform">
        <Form size='large' onSubmit={this.handleSubmit}>
        {/* <img className="logo" src={require('../images/logo.jpg')}/>
        Please enter your email address and password: */}
            <Segment stacked>
                <Form.Input
                id="email"
                fluid
                icon='user'
                iconPosition='left'
                placeholder='E-mail address'
                value={this.state.email}
                onChange={this.handleChange}                
            />
            <Form.Input
                id="password"
                fluid
                icon='lock'
                iconPosition='left'
                placeholder='Password'
                type='password'
                value={this.state.password}
                onChange={this.handleChange}                

            />
          <Button className='seaglassbutton' fluid size='large' disabled={!this.validateForm()} type="submit">Login</Button>
          </Segment> 
        </Form>
        </div>
        <Message negative hidden={!this.state.loginfailed}>
          <Message.Header>Login Failed</Message.Header>
          {this.state.loginfailedmessage}
          </Message> 
        {/* </Grid.Column>
      </Grid> */}
        <Modal style={modalStyle} size='small' open={this.state.showMFAChallenge}>
        <Modal.Header as='h3'>Enter One Time Password</Modal.Header>
        <Modal.Content>
        <Form>
          <Form.Input label='One Time Password' value={this.state.TOTP} onChange={(event, data)=>{this.setState({TOTP: data.value})}}/>
          <Button className='seaglassbutton' disabled={!this.state.TOTP} onClick={()=>{this.submitTOTP()}}>Submit</Button>
        </Form>
        </Modal.Content>
        </Modal>
        <Modal style={modalStyle} size='small' open={this.state.showSMSChallenge}>
        <Modal.Header as='h3'>Enter SMS Code</Modal.Header>
        <Modal.Content>
        <Form>
          <Form.Input label='One Time Password' value={this.state.SMSCode} onChange={(event, data)=>{this.setState({SMSCode: data.value})}}/>
          <Button className='seaglassbutton' disabled={!this.state.SMSCode} onClick={()=>{this.submitSMSCode()}}>Submit</Button>
        </Form>
        </Modal.Content>
        </Modal>        
        <ChangePassword visible={this.state.changePassword} email={this.state.email} user={this.state.user}text='You must change your password at the first login' onSuccess={this.closeChangePassword}/>

      </div>
    );
  }
}
