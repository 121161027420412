import React, { Component } from 'react';
import { Message, Segment, Table } from 'semantic-ui-react'
import "./Information.css"

export default class Information extends Component {
  constructor(props) {
    super(props);

  }
 
  renderSection(section) {
    if (section.style=="text") {return this.renderTextSection(section)}
    if (section.style=="messages") {return this.renderMessageSection(section)}
  }

  renderTextSection(section) {
    return(
      <Segment>
        <h2>{section.label}</h2>
        <Table className="infotable">
          <Table.Row>
              {section.items.map(item => this.renderTextRow(item))}
          </Table.Row>
        </Table>
      </Segment>
    )
  }

  renderMessageSection(section) {
    return(
        <Segment>
            <h2>{section.label}</h2>
            {section.items.map(item => this.renderMessage(item))}
        </Segment>
    )
  }

  renderTextRow(item) {
    return(
        <Table.Row>
            <Table.Cell>{item.label}</Table.Cell>
            <Table.Cell>{item.value}</Table.Cell>
        </Table.Row>
    )
  }

  renderMessage(item) {
    if (item.list) {
      return (<Message header={item.header} list={item.list} info size="mini" />)
    } else {
      return (
          <Message info size="mini">
            <Message.Header>{item.header}</Message.Header>
            <p>{item.detail}</p>
          </Message>
        )
    }
  }

  render() {
    return (
        <>
            {this.props.info.segments.map(section => this.renderSection(section))}
        </>
    )
  }

}
